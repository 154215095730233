export const GridColumnType = {
    Text: 1,
    LINK: 2,
    CHECKBOX: 3,
    TOGGLE: 4,
    LABLE: 5,
    DATE: 6,
    MONEY: 7,
    MASKED: 8,
    RADIO: 9,
    ACTION: 14,
    CUSTOM: 15,
    DROPDOWN: 16,
    // add other types

}
