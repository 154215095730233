
export const ErrorMessage = {
	DefaultMessage: 'Something went wrong',
	NOTVALIDPATTERN: '{0} must contain at least 8 alphanumeric, one special, one lower and upper case characters',
	FieldRequired: 'Please enter {0}',
	InvalidField: '{0} is invalid',
	UniqueName: 'Only letters, digits, hyphens, and ampersands are allowed',
	Website: 'Please enter valid {0}',
	PasswordNotValid: 'Invalid Password',
	ConfirmPasswordRequired: 'Please re-enter password',
	ComparePassword: 'Password does not match',
	CompareField: "{0} does not match",
	ValueGreaterZero: 'Value should be greater than zero',
	Max: 'Value should be less than {0}',
	Min: 'Value should be greater than {0}',
	PasswordNotMatch: 'Please enter correct password',
	selectFile: 'Please select {0} file',
	EmailAddressNotValid: 'Please enter valid email address',
	SelectRequired: 'Please select {0}',
	WebUrlPatternNotMatch: 'Please enter valid url in {0} field',
	DecimalPatternNotMatch: 'Please enter valid {0}',
	NoRecordFound: 'No Records found For {0}',
	PhoneValidation: 'Please enter valid phone number',
	FaxValidation: 'Please enter valid fax',
	ConfirmMessage: 'Are you sure want {0} this {1} ?',
	ActiveMessage: 'Active {0} successfully.',
	InActiveMessage: 'Inactive {0} successfully.',
	IsEnableMessage: 'Enable {0} successfully.',
	DisableMessage: 'Disable {0} successfully',
	SessionExpired: 'Session expired, please login again',
	InvalidAuthorization: 'Invalid Authorization Credentials ',
	InvalidFileType: 'Invalid file type',
	ProspectValue: '{0} value should be greater than zero',
	MaxProspectValue: '{0} value should be smaller than {1}',
	minEndDateValue: '{0} should be greater than Start Date',
	Already_Exist: '{0} already exist',
	validateQuantty: 'Please select at least one size',
	CompareEmail: 'Email address already exist',
	NotAvailabe: "N/A",
	CommonErrorMessage: "Please enter at least 3 characters to search or select a dropdown value.",
	SearchWarningMessage: "Please enter at least 3 characters to search.",
	NoFound: "No Records found",
	InvalidBoundarySpaces: "Only letters, digits, hyphens, ampersands, and spaces between characters are allowed.",
	FieldNameNotFound: "No fieldName property found in this rule",
	FileExists: "The file already exists. Please choose a different file name.",
	DocumentDetailMissing: "Your document details are missing",
	DocumentTypeExists: "Document Type already exists.",
	Invalidpostalcode: "The ZIP code is invalid. It should be {length} characters long.",
	InvalidSpecialCharacters: "The input contains invalid characters. Only letters and numbers are allowed.",
	ContactEmailAddressRequired: "Please provide at least one email address before proceeding.",
};

export const SuccessMessage = {
	Confirm_Question: 'Delete {0}?',
	Confirm_Delete: 'Are you sure you want to delete this {0}?',
	Delete_Success: '{0} deleted successful',
	Update_Success: '{0} updated successfully',
	Add_Success: '{0} added successfully',
	Insert_Success: '{0} inserted successfully',
	Already_Exist: '{0} already exist',
	Send_Success: '{0} send successfully',
	Charge_Success: '{0} charge successful',
	Confirm_Submit: 'Save {0}?',
	Confirm_Save: 'Are you sure you want to save this {0}?',
	Confirm_Update: 'Are you sure you want to change the {0}?',
	Confirm_Map: 'Are you sure you want to map?',
	Confirm_SubmitMap: 'Map?',
	Map_Alert: '{0} mapped successfully',
	Alret_Warning: 'Warning !',
	TaxId: 'Minimum {0} and Maximum {1} characters allowed for Tax Id',
	DefaultUSATaxId: 'Minimum 10 and Maximum 10 characters allowed.',
	// FieldsApprovalSuccess: "{0} is currently under review, and a response will be provided shortly.",
	// ApprovalRequestSuccess: "Other fields are updated successfully",
	FieldsApprovalExists: " {0} already exists in our records and, therefore, cannot be accepted.",
	ApprovalSuccess: "We are currently reviewing your request and will respond shortly. If approved, your request will be added or updated accordingly.",
	FinalSuccess: "We are currently reviewing your request for {0} and will respond shortly. If approved, your request will be added or updated accordingly. If any other data was included in the request, it has been successfully added or updated accordingly",
	VerifyProduct: "Please verify the product details before proceeding."
};