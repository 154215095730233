import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { APIUrl } from "../API/fetchBaseQuery";


// Define the async thunk for logging user login/logout history
export const logUserLoginLogoutHistory = createAsyncThunk('auth/logUserLoginLogoutHistory', async ({ userId, isLogin }, { rejectWithValue }) => {
    try {
        const response = await axios.post(`${APIUrl}/Authentication/AddUserLoginLogoutHistory`, {
            userId,
            isLogin
        });
        return response.data;
    } catch (error) {
        // Handle any errors here
        return rejectWithValue(error.response.data);
    }
}
);
